import * as React from 'react'
// @ts-ignore
import * as headerStyles from '../styles/header.module.css' 
import { StaticImage } from 'gatsby-plugin-image'

interface HeaderProps {
  siteTitle: string
}

const Header = ({ siteTitle }: HeaderProps) => {
  return (
    <header className={headerStyles.headerContainer}>
      <StaticImage
        src="../../data/images/portfolio-site-image.jpg"
        alt="Brandon Dusch"
        className={headerStyles.heroImage}
        width={150}
      />
    </header>
  )
}

export default Header
